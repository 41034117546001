import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomButtonGroup } from "../common/CustomArrows";
import "./CaruselCursos.css";
import "./CaruselTest.css";
import TarjetaTest from "./TarjetaTest";

const CaruselCursos = (props) => {
  const [profesores, setProfesores] = useState([]);
  //setProfesores(props.profesores);
  //const loco = props.profesores;

  useEffect(() => {
    setProfesores(props.profesores);
    /* console.log("mi props es: ", props.profesores); */
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const items = profesores.map((profesor, index) => {
    return (
      <TarjetaTest profesor={profesor} key={`tarjeta-profesor-${index}`} />
    );
  });

  return (
    <div className={"carousel-parent carusel-profes"}>
      <h2 className="tipo-de-cursos">{"Staff de Docentes"}</h2>
      <div className="carousel-container" style={{ position: "relative" }}>
        <Carousel
          responsive={responsive}
          arrows={false}
          customButtonGroup={<CustomButtonGroup />}
          renderButtonGroupOutside={true}
          centerMode={false}
        >
          {items}
        </Carousel>
      </div>
    </div>
  );
};

export default CaruselCursos;
