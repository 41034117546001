import {
  faChalkboardTeacher,
  faFlag,
  faGlobe,
  faGraduationCap,
  faThumbsUp,
  faUserGraduate,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import banner from "../../images/Banner-home-2.jpg";
import fondoContador from "../../images/Banner-home-3.jpg";
import imagenSeccionOrientado from "../../images/Img-banner.png";
import persona from "../../images/Personaje-home-2.png";
import profeJorge from "../../images/profe-jorge.jpg";
import profeJoseAvalos from "../../images/profe-jose-avalos.jpg";
import profeJoseMaria from "../../images/profe-jose-ma.jpg";
import profeKatia from "../../images/profe-katia.jpg";
import profeMario from "../../images/profe-mario.jpg";
import profeNelson from "../../images/profe-nelson.jpg";
/* import profeRaul from "../../images/profe-raul.jpg"; */
import sello from "../../images/Sello.png";
import video from "../../videos/Recorte-01.mp4";
import Banner from "../common/Banner";
import BotonDeSubir from "../common/BotonDeSubir";
import CaruselCursos from "../common/CaruselCursos";
import CaruselTests from "../common/CaruselTests";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
/* import ModalCookies from "../common/ModalCookies"; */
import ApiGuardarCache from "../utlis/ApiGuardarCache";
import VideoJS from "../utlis/VideoJS";
import "./css/Home.css";
/* import LiteYTEmbed from "lite-youtube-embed";
import "lite-youtube-embed/src/lite-yt-embed.css"; */

/* https://www.sitemaps.org/protocol.html */

/*

<img src={profeJoseAvalos} alt="" />
            <h3>José Avalos</h3>
            <span>Microsoft Office Specialist</span>
            <p>
              Prof. certificado en Microsoft Office Specialist Docente
              especializado en Audiovisuales y Efectos Especiales
            </p>
          </div>
*/

const Home = () => {
  const docentes = [
    {
      foto: profeJorge,
      nombre: "Jorge Castrillón",
      titulo: "Especialista en Innovación y Marketing Educativo",
      /* descripcion:
        "Prof. certificado en Microsoft Office Specialist Docente especializado en Scratch y Robótica Educativa", */
    },
    {
      foto: profeKatia,
      nombre: "Katia Franco",
      titulo: "Especialista en Diseño y Comunicación Digital",
      /* descripcion:
        "Prof. especializada en Ofimática y Alfabetización Digital Docente STEAM Educativo y Grupo IDT", */
    },
    {
      foto: profeJoseMaria,
      nombre: "José Ma. García",
      titulo: "Especialista en Comunicación Audiovisual y Multimedia",
      /* descripcion:
        "Prof. especializado en Ofimática y Alfabetización Digital Supervisor Académico en la Fundación Protección Online", */
    },
    {
      foto: profeNelson,
      nombre: "Nelson Cardozo",
      titulo: "Especialista en Comunicación y Marketing Digital",
      /* descripcion:
        "Prof. certificado en Microsoft Office Specialist Docente especializado en Audiovisuales y Efectos Especiales", */
    },
    {
      foto: profeMario,
      nombre: "Mario Velázquez",
      titulo: "Especialista en Dirección de IE y Marketing Educativo",
      /* descripcion:
        "Prof. certificado en Microsoft Office Specialist Docente especializado en Audiovisuales y Efectos Especiales", */
    },
    /*     {
      foto: profeRaul,
      nombre: "Raul Álvarez",
      titulo: "Microsoft Office Specialist",
      descripcion:
        "Prof. certificado en Microsoft Office Specialist Docente especializado en Audiovisuales y Efectos Especiales",
    }, */
    {
      foto: profeJoseAvalos,
      nombre: "José Ávalos",
      titulo:
        "Microsoft Office Specialist, Aulas Virtuales, Innovación Educativa",
      /* descripcion:
        "Prof. certificado en Microsoft Office Specialist Docente especializado en Audiovisuales y Efectos Especiales", */
    },
  ];

  const [fechaTope, setFechaTope] = useState(false);
  useEffect(() => {
    ApiGuardarCache("tiempo")
      .then((r) => {
        /* console.log("es la r: ", r); */
        //const [fechaTope, setFechaTope] = useState(new Date());
        setFechaTope(
          new Date(
            r.fin.anho,
            r.fin.mes,
            r.fin.dia,
            r.fin.hora,
            r.fin.minuto,
            r.fin.segundo
          )
        );
        /* console.log("nueva fechatope: ", fechaTope); */
      })
      .catch((err) => console.log(err));
    return () => clearInterval(1);
  }, []);

  const youtube = useRef();

  useEffect(() => {
    //console.log("corriente",mail.current);
    setTimeout(() => {
      console.log("YouTube: ", youtube.current);
    }, 2000);
  }, [youtube?.current?.children?.length]);

  /**
   * Video ini
   */

  const playerRef = useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    techOrder: ["youtube"],
    sources: [{ type: "video/youtube", src: "https://www.youtube.com/watch?v=xjS6SftYQaQ"}]/* 
    sources: [{
      src: video,
      type: 'video/mp4'
    }] */
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };
  
  /**
   * Video fin
   */

  return (
    <>
      <Helmet>
        <title>Cursos de Marketing Educativo</title>
        <meta
          name="description"
          content="Ayudamos a maximizar los resultados de tu negocio educacional. Con nuestros Cursos de Marketing Educativo podrás mejorar la captación, retención y fidelización."
        />
        <meta name="keyword" content="curso de marketing digital" />
      </Helmet>
      <BotonDeSubir />
      <MensajeDescuento />
      <Header />
      <Banner />
      {fechaTope !== false && <CtaHome fecha={fechaTope} />}

      <section className="first-section">
        <div className="video-section">
          <h2>¿Tu institución no crece?</h2>
          {/* <video className="video-player" controls>
            <source src={video} type="video/mp4" />
          </video> */}
          {/* width="790" height="444" */}
          {/* <iframe
            className="video-player"
            src="https://www.youtube.com/embed/bD8Oszhs3J0?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> */}
          <iframe
            ref={youtube}
            className="video-player"
            src="https://www.youtube.com/embed/kH6YaCdCPFc?rel=0&end=45"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          {/* <lite-youtube videoid="bD8Oszhs3J0"></lite-youtube> */}

          {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}

        </div>
        <div className="content-home">
          <p>
            Ayudamos a maximizar tus metas del año, con nuestros cursos que{" "}
            <br />
            están actualizados al 2022 con las últimas y mejores tendencias del{" "}
            <br />
            <strong> Marketing Educativo </strong>hispano, de la mano de
            verdaderos profesionales <br />
            que impulsarán a tu centro de formación a lograr no sólo una mayor{" "}
            <br />
            rentabilidad, captación y fidelización, sino también a mejorar sus{" "}
            <br />
            distintas áreas de gestión, ya que el
            <strong> Marketing Educacional </strong>involucra <br />a todos las
            miembros de una institución educativa.
          </p>
          {/* <h3 className="text-azul">
            <span className="precio">USD 159</span> y por tiempo limitado (
            <span>en vez de USD 355</span>),
          </h3> */}
          <h3>
            Suscríbete ahora con un pago único anual y con nuestro descuento
            actual, ¡ahorra hasta 70%!
          </h3>
          <a href="/inscribirse">
            <button type="button" className="boton-inscribirse">
              ¡INSCRIBIRSE AHORA!
            </button>
          </a>
        </div>
      </section>

      <section className="second-section">
        <p className="second-section__title">¡ES TIEMPO DE REINVENTARSE!</p>
        <p className="second-section__subtitle">
          La forma de comunicar, ofertar y posicionar servicios educativos ha
          cambiado.
        </p>
        <p>
          Las instituciones educativas en estos nuevos tiempos deben,
          <br />
          imperiosamente, reformular sus estrategias de posicionamiento para
          <br />
          proyectar su liderazgo. Necesitan planificar y ejecutar acciones
          <br />
          innovadoras a través del Marketing Educativo, que les permitan una
          <br />
          mayor captación, retención y fidelización de estudiantes.
        </p>
        <p>
          Es determinante que comprendan cómo analizar y medir sus ofertas
          <br />
          educativas actuales y proyectar las futuras. Pero además es muy
          <br />
          importante el cómo comunicarlas adecuadamente para lograr su evolución
          <br />y éxito, llegando a convencer al alumnado deseado.
        </p>
        <a href="/inscribirse">
          <button type="button" className="boton-inscribirse-2">
            ¡INSCRIBIRSE AHORA!
          </button>
        </a>
      </section>

      <section className="container carousel-home">
        <CaruselCursos
          description="Para poner en práctica las principales estrategias actuales que ayudarán a tu centro de formación a dar el salto que necesita."
          category={"147"}
          title="Cursos de Marketing Educativo"
        />
      </section>

      <hr className="linea" />

      <section className="container carousel-home segundo-carusel">
        <CaruselCursos
          description="Ayudarán a lograr excelentes resultados en la captación, retención, fidelización y posicionamiento de la marca institucional."
          category={"146"}
          title="Cursos de Marketing Digital para Instituciones Educativas"
        />
      </section>

      <section className="cta-bg" style={{ backgroundImage: `url(${banner})` }}>
        <div className="texto-descuento-container">
          <h3>
            <span className="texto-amarillo">
              <span className="numero-grande">70</span>
              <span className="porcentaje">%</span>
              <br />
              <span className="numero-pequeño">de descuento ya,</span>
            </span>
            <br />
            para acceder a todos nuestros
            <br />
            cursos, ¡por tiempo limitado!
          </h3>
          <p>
            ¡Accede a los mejores y más completos
            <br />
            cursos de Marketing Educativo!
          </p>
          <a href="/inscribirse">
            <button type="button">¡Inscribirse Ahora!</button>
          </a>
        </div>
      </section>

      <section className="porque-cdo">
        <h2 className="titulo-quinta-seccion">¿Por qué Marketing Educativo?</h2>
        <div className="container grid">
          <div className="tarjeta-home">
            <FontAwesomeIcon icon={faUsers} />
            <div className="content-home">
              <h3>Confianza</h3>
              <p>
                En estos tiempos donde la confianza es un factor fundamental,
                nosotros nos destacamos porque ofrecemos garantía de servicio.
                Si no estás satisfecho con el servicio, te reembolsamos la
                inversión con garantía incluso hasta dentro de los 30 (treinta)
                días de tu suscripción si no has recibido lo comprometido. Así
                de seguros nos sentimos para estrechar vínculos contigo y tu
                institución educativa.
              </p>
            </div>
          </div>

          <div className="tarjeta-home-amarillo">
            <FontAwesomeIcon icon={faGraduationCap} />
            <div className="content-home">
              <h3>Experiencia</h3>
              <p>
                Somos expertos brindar capacitación basada en las nuevas
                tecnologías y del Mkt Educativo. El Grupo IDT tiene 24 años de
                trabajo y experiencia en el sector educativo, y nuestros
                servicios los sustentamos en la innovación y la calidad absoluta
                en cada uno de nuestros cursos. Contamos con la mejor
                metodología, el mejor plantel docente / profesional, y las
                mejores tecnologías aliadas.
              </p>
            </div>
          </div>

          <div className="tarjeta-home-amarillo">
            <FontAwesomeIcon icon={faChalkboardTeacher} />
            <div className="content-home">
              <h3>Conveniencia</h3>
              <p>
                En los Cursos de Marketing Educativo que te ofrecemos podrás
                encontrar formaciones a propio ritmo específicas orientadas al
                ámbito y desarrollo del área, pero también como beneficio
                adicional sumamos cursos transversales que ayudarán a posicionar
                mejor a los servicios que ofrece tu institución educativa o
                mismo, a tu perfil personal para un mayor crecimiento
                profesional.
              </p>
            </div>
          </div>

          <div className="tarjeta-home">
            <FontAwesomeIcon icon={faThumbsUp} />
            <div className="content-home">
              <h3>Calidad</h3>
              <p>
                Estamos brindando los programas curriculares más actualizados al
                2022 con la mayor calidad del mercado hispano, y los iremos
                actualizando periódicamente con nuevos cursos de interés, que
                lleguen a ser útiles para los niveles superiores académicos y
                operativos de las instituciones educativas primarias,
                secundarias, universitarias y los institutos de capacitación y
                profesionalización.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="orientado-container">
        <h2 className="orientado-titulo">
          A quiénes van orientados nuestros Cursos
          <br />
          de Marketing Educativo
        </h2>
        <div className="orientado-info-container">
          <div className="orientado-info">
            <p>
              Todas las instituciones y centros educativos tienen <br />
              actualmente puntos en comunes: necesitan mejorar su <br />
              oferta educativa, necesitan comunicar sus valores y <br />
              diferenciales a fin de poder realizar acciones de <br />
              promoción, captación, retención y fidelización de sus <br />
              alumnos de manera eficiente.
            </p>
            <p>
              Por ello, nuestros cursos están orientados a: Directores <br />
              generales y promotores de instituciones educativas, <br />
              decanos, académicos, gerentes, directores, jefes, <br />
              coordinadores y profesionales de marketing, relaciones <br />
              públicas, comunicación y publicidad de los centros <br />
              educativos. Consultores especializados en educación, <br />
              desarrollo estratégico, comunicación y gestión de centros <br />
              educativos. Profesionales relacionados con la industria <br />
              educativa en Universidades, Colegios, Escuelas, Institutos e{" "}
              <br />
              Instituciones Educativas en general.
            </p>
            <a href="/inscribirse">
              <button className="boton-inscribirse-orientado">
                ¡INSCRIBIRSE AHORA!
              </button>
            </a>
          </div>
          <div className="orientado-img">
            <img
              src={imagenSeccionOrientado}
              alt="Imagen de grupo de gente trabajando"
            />
          </div>
        </div>
      </section>

      <section
        className={"counters"}
        style={{ backgroundImage: `url(${fondoContador})` }}
      >
        <div className="container-items">
          <div className={"counter-items"}>
            <FontAwesomeIcon icon={faFlag} />
            <h3>24</h3>
            <h4>CURSOS EN EL CAMPUS</h4>
          </div>
          <div className={"counter-items"}>
            <FontAwesomeIcon icon={faGlobe} />
            <h3>301</h3>
            <h4>LECCIONES</h4>
          </div>
          <div className={"counter-items"}>
            <FontAwesomeIcon icon={faChalkboardTeacher} />
            <h3>50</h3>
            <h4>HORAS DE VIDEOS</h4>
          </div>
          <div className={"counter-items"}>
            <FontAwesomeIcon icon={faUsers} />
            <h3>1200</h3>
            <h4>ESTUDIANTES</h4>
          </div>
        </div>
      </section>

      <section className="container carousel-home-docentes">
        <CaruselTests profesores={docentes} />
      </section>

      {/*       <section className={"docentes-section"}>
        <h2>Staff de Docentes</h2>
        <CaruselProfesores profesores={docentes} />
      </section> */}

      {/*       <section
        className={"container docentes-section"}
        style={{ display: "none" }}
      >
        <h2>Staff de Docentes</h2>
        <div className="docentes-list">
          <div className="docente">
            <img src={profeJorge} alt="" />
            <h3>Jorge Castrillón</h3>
            <span>Microsoft Office Specialist</span>
            <p>
              Prof. certificado en Microsoft Office Specialist Docente
              especializado en Scratch y Robótica Educativa
            </p>
          </div>

          <div className="docente">
            <img src={profeKatia} alt="" />
            <h3>Katia Franco</h3>
            <span>Ofimática y Alfabetización Digital</span>
            <p>
              Prof. especializada en Ofimática y Alfabetización Digital Docente
              STEAM Educativo y Grupo IDT
            </p>
          </div>

          <div className="docente">
            <img src={profeJoseMaria} alt="" />
            <h3>José Ma. García</h3>
            <span>Ofimática y Alfabetización Digital</span>
            <p>
              Prof. especializado en Ofimática y Alfabetización Digital
              Supervisor Académico en la Fundación Protección Online
            </p>
          </div> */}

      {/*           <div className="docente">
            <img src={profeJoseAvalos} alt="" />
            <h3>José Avalos</h3>
            <span>Microsoft Office Specialist</span>
            <p>
              Prof. certificado en Microsoft Office Specialist Docente
              especializado en Audiovisuales y Efectos Especiales
            </p>
          </div> */}

      {/*           <div className="docente">
            <img src={profeNelson} alt="" />
            <h3>Nelson Cardozo</h3>
            <span>Microsoft Office Specialist</span>
            <p>
              Prof. certificado en Microsoft Office Specialist Docente
              especializado en Audiovisuales y Efectos Especiales
            </p>
          </div>
        </div>
      </section> */}

      <section className="garantia">
        <div className="container-garantia">
          <div className="imagen">
            <img
              src={persona}
              className="foto-persona"
              alt="Imagen de persona apuntando"
            />
          </div>
          <div className="seccion-informacion">
            <h3 className="garantia-titulo">
              ¡Satisfacción
              <br />
              100% garantizada
              <br />
              por 30 días!
            </h3>
            <p>
              Si los cursos de Marketing Educativo que te <br />
              ofrecemos no son de tu satisfacción, <br />
              te reembolsaremos tu dinero. <br />
            </p>
            <span>
              <a
                href="/terminos-y-condiciones#reembolso"
                target="_blank"
                className="condiciones-home"
              >
                * Consultar condiciones
              </a>
            </span>
            <div className="titulo-garantia">
              <h2>
                Garantía de <br /> servicios
              </h2>
              <img src={sello} alt="Imagen de sello de satisfacción" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* <ModalCookies /> */}
    </>
  );
};

export const CtaHome = (props) => {
  const te = 0;
  let fechaTope = props.fecha; //new Date(2022, 12, 31, 23, 21, 59);
  //const fechaTope = ;

  const ahora = new Date();
  //console.log("fechaTope " + fechaTope);
  const transcurso =
    (parseInt(fechaTope.getTime()) - parseInt(ahora.getTime())) / 1000;
  const [seconds, setSeconds] = useState(transcurso); // 86400
  const [time, setTime] = useState({ d: 0, h: 0, m: 0, s: 0 });

  const secondsToTime = (secs) => {
    let days = Math.trunc(secs / (60 * 60 * 24));

    let divisor_for_hours = secs % (60 * 60 * 24);
    let hours = Math.floor(divisor_for_hours / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.floor(divisor_for_seconds);

    setTime({
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((s) => s - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (fechaTope.getTime() >= ahora.getTime()) {
      secondsToTime(seconds);
    } else {
      secondsToTime(0);
    }
  }, [seconds]);

  return (
    <>
      <div className="container-countdown">
        <div className="cta-home">
          <h2>
            ¡Los mejores cursos de Marketing
            <br />
            Educativo con un gran descuento
            <br />
            por <span>tiempo limitado!</span>
          </h2>
          <div className="countdown-holder">
            <div className="countdown">
              <div>
                <h2>{time.d}</h2>
                <span>Días</span>
              </div>
              <div>
                <h2>{time.h}</h2>
                <span>Horas</span>
              </div>
              <div>
                <h2>{time.m}</h2>
                <span>Minutos</span>
              </div>
              <div>
                <h2>{time.s}</h2>
                <span>Segundos</span>
              </div>
            </div>
            {time.d === 0 && time.h === 0 && time.m === 0 && time.s === 0 ? (
              ""
            ) : (
              <Link to="/inscribirse">
                <FontAwesomeIcon icon={faUserGraduate} />
                ¡INSCRIBIRSE AHORA!
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
