import React, { useEffect, useState } from "react";
import ApiCache from "../utlis/ApiCache";
import AccederPy from "./AccederPy";
import AccederUni from "./AccederUni";
/* import "./css/Acceder.css"; */
import "./css/Nosotros.css";

const Acceder = () => {
  const [pais, setPais] = useState("");
  const endpointIpPage = "https://ipapi.co/json";

  const endpointIpAux = "https://marketingeducativo.com/ip.php";

  const consultarIp = async (endpoint) => {
    const datos = await fetch(endpoint);
    const json = await datos.json();
    return json;
  }

  useEffect(() => {
    ApiCache(`datos-ip`, endpointIpPage)
      .then((r) => {
        setPais(r.country);
        /* console.log(r.country); */
      })
      .catch((err) => {
        /* console.log(err); */
        const datos = consultarIp(endpointIpAux);
        const pais = datos?.country;
        if (pais !== null || pais !== undefined || pais !== "") {
          setPais(pais);
        } else {
          setPais("");
        }
      });
  }, [endpointIpPage]);

  if (pais === "PY") {
    return <AccederPy />;
  } else {
    return <AccederUni />;
  }
};

export default Acceder;
