import { Helmet } from "react-helmet";
import background from "../../images/Banner-Acceder.jpg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import SectionTitle from "../common/SectionTitle";
import "./css/AccederPy.css";
import "./css/Nosotros.css";

const FinalizacionCompra = () => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Inscribirse - Marketing Educativo</title>
        <meta
          name="description"
          content={"Inscribirse - Marketing Educativo"}
        />
        <meta name="keyword" content="curso de Marketing y Ventas" />
      </Helmet>
      <SectionTitle title={"Confirmar Inscripción"} />
      <section
        className={"section-title-tipo"}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="info-container">
          {/* <img src={persona} alt="" className="img-fluid tipo" /> */}
          <div className="text">
            <span className="pago-anual">
              Pago de Inscripción Anual promocional de
            </span>
            <span className="precio">
              <span className="usd">Gs. 1.200.000, por tiempo limitado.</span>
              <br />
              <span className="precio-original">(en vez de Gs. 2.345.000)</span>
            </span>
            <div className="info-suscripcion">
              <ul>
                <li>
                  Por tu suscripción, hoy, te ofrecemos 24 cursos, y a lo largo
                  de este año con tu mismo plan
                  <br />
                  te sumaremos más cursos de interés y calidad, y no deberás
                  abonar nada adicional para
                  <br />
                  beneficiarte de los mismos.
                </li>
                <li>
                  Son ahora 24 cursos, y cada uno de ellos ahora, y por tiempo
                  limitado, solo te cuestan Gs. 50.000.
                  <br />
                  Cada uno de ellos te aseguramos impctarán a tu enfoque
                  profesional.
                </li>
                <li>
                  Te brindamos calidad garantizada por 30 días, o te devolvemos
                  tu inversión.
                </li>
                <li>
                  No improvisamos, somos lídere en el ámbito educativo,
                  tecnológico y de las ventas y del
                  <br />
                  marketing desde hace 24 años.
                </li>
                <li>
                  Nuestros docentes son referentes, con basta experiencia
                  profesional y educacional.
                </li>
                <li>
                  Nuestra currícula y temas educativos, están alineados y
                  actualizados a la necesidad de la
                  <br />
                  industria educativa actual, independientemente al sector en el
                  que hagan énfasis.
                </li>
                <li>
                  Conocemos y transferimos los mejores factores claves para
                  hacer frente a estos nuevos tiempos,
                  <br />
                  de los que cada institución educativa necesita (sean escuelas,
                  coledios, unversidades, institutos,
                  <br />
                  academias, etc.) para escalar en sus matriculaciones,
                  retenciones y fidelizaciones. Por ello
                  <br />
                  desarrollamos cursos que abarcan los aspectos más clave para
                  que logren el éxito y proyectar
                  <br />
                  sus logros a corto, mediano y largo plazo.
                </li>
              </ul>
              <button className="boton-seccion-inscribirse">
                <a href="#container-form">¡INSCRIBIRSE AHORA!</a>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/*     <h3 style={ {textAlign:'center', margin:100}}>En construcción, loco</h3> */}
      {/* <Formd /> */}
      <section className={"container-form"} id="container-form">
        <div>
          <h3>
            ESTAMOS PROCESANDO SU PEDIDO!, LE LLEGARA UN MAIL DE CONFIRMACION!
          </h3>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FinalizacionCompra;
