import { hydrate, render } from "react-dom";
import App from "./App";
import { DataProvider } from "./Context/DataContext";
/* import reportWebVitals from "./reportWebVitals"; */
//import ReactDOM from 'react-dom';
import "./index.css";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <DataProvider>
      <App />
    </DataProvider>,
    rootElement
  );
} else {
  render(
    <DataProvider>
      <App />
    </DataProvider>,
    rootElement
  );
}

/*ReactDOM.render(
  <React.StrictMode>
  <App />
</React.StrictMode>,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
