const mejorarUrl = (texto) => {
    if (texto === null || texto === "" || texto === undefined) {
        return "";
    }
    const textoSinEspacio = texto.replace(/ /g,"-");
    const textoMinuscula = textoSinEspacio.toLowerCase();
    const textoSinAcento = textoMinuscula.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const nuevoTexto = encodeURI(textoSinAcento);
    /* console.log("nuevoTexto", nuevoTexto); */
    return `${nuevoTexto}`;
};

export default mejorarUrl;
