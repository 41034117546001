import { createContext, useState } from "react";

const DataContext = new createContext();

const DataProvider = ({ children }) => {
  const [mensajeVisible, setMensajeVisible] = useState("flex");

  return (
    <DataContext.Provider
      value={{
        mensajeVisible,
        setMensajeVisible,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
