const mejorarTituloBlog = (titulo) => {
    const mejorar = titulo.toLowerCase();
    const mejorar1 = mejorar
    .replace("&#8211;", "-")
    .replace("5 claves", "5 Claves")
    .replace("ie", "IE")
    .replace("institución educativa", "Institución Educativa");
    return mejorar1;
  };
  
  export default mejorarTituloBlog;
  