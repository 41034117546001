import banner from "../../images/franja.jpg";
import "./SectionTitle.css";

const SectionTitle = (props) => {
  return (
    <>
      <section
        className={"section-title"}
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container">
          <h1>
            {(props.title ?? "Cursos de Marketing y Ventas") !==
            "cursos-habilitados"
              ? props.title ?? "Cursos de Marketing y Ventas"
              : "TODOS ESTOS CURSOS POR UN PAGO ÚNICO ANUAL DE *USD 93"}
          </h1>
          {(props.title ?? "Cursos de Marketing y Ventas") === "cursos-habilitados" && <h2>(* Promoción por tiempo limitado)</h2>}
        </div>
      </section>
    </>
  );
};

export default SectionTitle;
