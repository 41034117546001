import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/es-mx";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../common/BreadCrumbs";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import ApiCache from "../utlis/ApiCache";
import "./css/Blog.css";

const Categoria = (props) => {
  const cantporpagina = 10;
  const [pageCount, setPageCount] = useState(0);
  const [posts, setPosts] = useState([]),
    [post, setPost] = useState({}),
    [isLoading, setIsLoading] = useState(true),
    [page, setPage] = useState(1),
    [filters, setFilters] = useState(""),
    [cantidad, setCantidad] = useState(0),
    [isSingle, setIsSingle] = useState(false);

  const categorias = {
    Technology: 33,
    Hobby: 32,
    Adverisement: 31,
    Uncategorized: 0,
  };
  const lista_categorias_id = [31, 32, 33, 0];
  //const lista_categorias_nombre = ["Tecnology", "Hobby", "Adverisement"]

  let { slug } = useParams();
  const locale = "es";

  let category = categorias[slug];
  //console.log("soy slugma: "+ slug)
  //console.log("soy category de level: "+ category)

  const endpoint =
      process.env.REACT_APP_API_WP +
      `posts?categories=${category}&page=${page}&_embed`,
    endpointSingle = process.env.REACT_APP_API_WP + `posts?slug=${slug}&_embed`;
  //console.log('endpoint: '+ endpoint);
  //console.log('endpointSingle: '+ endpointSingle);
  posts.map((p) => {
    return p;
  });

  const testt = async () => {
    let w = await fetch(endpoint);
    const poster = w.headers.get("x-wp-total");
    //console.log(poster);
    setCantidad(poster);
    setPageCount(Math.ceil(cantidad / cantporpagina));
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    //console.log("pagina seleccionada: "+ selectedPage);
    if (selectedPage == null || selectedPage == 0) {
      setPage(1);
    } else {
      setPage(selectedPage);
    }
  };

  testt();

  /**/
  /*const handlePageClick = (e) => {
        console.log(e);

    };*/
  /**/

  useEffect(() => {
    setPage(page);
    setFilters("0");

    moment.locale(locale);

    setIsLoading(true);

    //const categorias = {"Tecnology" : 33, "Hobby": 32, "Adverisement": 31}
    //const lista_categorias_id = [31, 32, 33];
    const lista_categorias_nombre = ["Tecnology", "Hobby", "Adverisement"];

    if (/*lista_categorias_id.includes.(category)*/ true) {
      //console.log('slug es unndefined!');
      //console.log('page: '+ page);
      //console.log('filters: '+ filters);
      setIsSingle(false);
      ApiCache(
        `todos-categorias-page-${page}-filters-${categorias[slug]}`,
        endpoint
      )
        .then((r) => {
          //console.log(r);
          setPosts(r);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } /*else {
            console.log('slug no es unndefined!');
            setIsLoading(true);
            setIsSingle(true);
            ApiCache(`single-blog-${slug}`, endpointSingle)
                .then((r) => {
                    setPost(r[0]);
                    setIsLoading(false);
                })
                .catch((err) => console.log(err))
        }*/
  }, [slug, endpoint, endpointSingle, filters, page]);
  /*
    slug, endpoint, endpointSingle, filters, page */

  if (isLoading) {
    return (
      <>
        <MensajeDescuento />
        <Header />
        <SectionTitle title={"Cargando.."} />
        <div className="container">
          <Loader
            className={"mainLoader"}
            type="Rings"
            color="#3b6075"
            height={100}
            width={100}
          />
        </div>
        <Footer />
        <ModalCookies />
      </>
    );
  }

  if (isSingle) {
    return (
      <>
        <MensajeDescuento />
        <Header />
        <SectionTitle title={post?.title?.rendered ?? " sin titulo"} />
        <BreadCrumbs
          path={[
            {
              id: 0,
              label: "Cursos de Marketing y Ventas",
              link: "/",
              current: false,
            },
            {
              id: 1,
              label: "Blog",
              link: "/blog",
              current: false,
            },
            {
              id: 2,
              label: /*'test' */ post?._embedded["wp:term"][0][0]?.name,
              link:
                "/categoria/" +
                  /*'test'*/ post?._embedded["wp:term"][0][0]?.name ?? "?",
              current: false,
            },
            {
              id: 3,
              label: post?.title?.rendered,
              link: null,
              current: true,
            },
          ]}
        />
        <div className="container blog-container">
          <main>
            <h2>{post?.title?.rendered}</h2>
            <div className="post-meta">
              <FontAwesomeIcon icon={faClock} />
              {moment(post?.modified).format("LL")}
            </div>

            <img
              src={
                post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                  "img-1170-500"
                ]?.source_url ?? "default.jpg"
              }
              alt=""
              className={"img-fluid"}
            />
            <div
              className="post-content"
              dangerouslySetInnerHTML={{
                __html: post?.content?.rendered ?? "",
              }}
            />

            {/*
                        todo: tal vez esto..
                        <div className="social-share">
                            <span>Compartir: </span>
                        </div>*/}
          </main>
          <aside>
            <Sidebar />
          </aside>
        </div>
        <Footer />
        <ModalCookies />
      </>
    );
  } else {
    //console.log(posts);

    const publicaciones = posts.map((x) => {
      return (
        <Link key={"sidebar-" + x.id} to={`/blog/${x.slug}`}>
          <section className="card-container">
            <div className="post-item">
              <img
                src={
                  x?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                    "img-480-380"
                  ]?.source_url ?? "default.jpg"
                }
                alt={x.title.rendered}
              />
            </div>
            <div className="content-container">
              <div className="fecha">
                <span className="fecha__numero">12</span>
                <span className="fecha__iniciales">MAY</span>
              </div>
              <div>
                <div className="content">
                  <h2 className={"title"}> {x.title.rendered}</h2>
                  <p className={"font-black"}>
                    {x.excerpt.rendered
                      .replace("<p>", "")
                      .replace("</p>", "")
                      .replace("&hellip;", "...")}
                  </p>
                </div>
                <div className="separador"></div>
              </div>
            </div>
          </section>
        </Link>
      );
    });
    //console.log(publicaciones);
    //console.log("soy la pagina"+ page);

    return (
      <div>
        <MensajeDescuento />
        <Header />
        <SectionTitle title={slug} />

        <div className="container-publicaciones">
          <main className="main-section">{publicaciones}</main>
          <aside>
            <Sidebar />
          </aside>
          <div className="container-pagination">
            <ReactPaginate
              previousLabel={"anterior"}
              nextLabel={"siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={page}
            />
          </div>
        </div>
        <Footer />
        <ModalCookies />
      </div>
    );
  }
};

const Sidebar = (props) => {
  const [buscado, setBuscado] = useState("");
  //console.log('buscado');
  const endpointPosts =
    process.env.REACT_APP_API_WP + "posts?per_page=2&_embed";
  //const endpointPostsSearch = process.env.REACT_APP_API_WP + 'search?search='+buscado+'&per_page=50&page=1';
  const [posts, setPosts] = useState([]),
    [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    ApiCache(`blog-sidebar-last`, endpointPosts)
      .then((r) => {
        setPosts(r);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [endpointPosts]);

  const recientes = posts.map((post) => {
    return (
      <Link key={"sidebar-" + post.id} to={`/blog/${post.slug}`}>
        <div className="sidebar-post-item">
          <div className="img-squared">
            <img
              src={
                post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                  "thumbnail"
                ]?.source_url ?? "default.jpg"
              }
              alt={post.title.rendered}
            />
          </div>
          <h2 className={"title"}> {post.title.rendered}</h2>
        </div>
      </Link>
    );
  });
  //console.log('recientes:');
  //console.log(recientes);

  function buscar() {
    alert("test bro" + buscado);
  }

  return (
    <>
      <div className="sidebar-section">
        <div className="sidebar-decorator" />
        <h2>Recientes</h2>

        {recientes}
      </div>

      <div className="sidebar-section">
        <h2>Buscador</h2>
        <div className="sidebar-search">
          <input
            type="text"
            placeholder="buscar"
            onChange={(e) => {
              setBuscado(e.target.value);
            }}
          />
          <button onClick={buscar}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>

      {/*
            <div className="sidebar-section">
                <h2>Archivo</h2>
                <div className="sidebar-search">
                    <select name="search">
                        <option value="all">
                            Elegir mes
                        </option>
                    </select>
                </div>
            </div>
            */}
    </>
  );
};

export default Categoria;
