import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import background from "../../images/Banner-Acceder.jpg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import "./css/AccederPy.css";
import "./css/Nosotros.css";
import Form2co from "./Form2co";

const AccederEs = () => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Inscribirse - Marketing Educativo</title>
        <meta
          name="description"
          content={"Inscribirse - Marketing Educativo"}
        />
        <meta name="keyword" content="curso de Marketing y Ventas" />
      </Helmet>
      <SectionTitle title={"Confirmar Inscripción"} />
      <section
        className={"section-title-tipo"}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="info-container">
          {/* <img src={persona} alt="" className="img-fluid tipo" /> */}
          <div className="text">
            <span className="pago-anual">
              Pago de Inscripción Anual promocional de
            </span>
            <span className="precio">
              <span className="usd">€ 280, por tiempo limitado.</span>
              <br />
              <span className="precio-original">(en vez de € 510)</span>
            </span>
            <div className="info-suscripcion">
              <ul>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Por tu suscripción, hoy, te ofrecemos 24 cursos, y a lo
                    largo de este año con tu mismo plan
                    <br />
                    te sumaremos más cursos de interés y calidad, y no deberás
                    abonar nada adicional para
                    <br />
                    beneficiarte de los mismos.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Son ahora 24 cursos, y cada uno de ellos ahora, y por tiempo
                    limitado, solo te cuestan Gs. 50.000.
                    <br />
                    Cada uno de ellos te aseguramos impctarán a tu enfoque
                    profesional.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Te brindamos calidad garantizada por 30 días, o te
                    devolvemos tu inversión.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    No improvisamos, somos lídere en el ámbito educativo,
                    tecnológico y de las ventas y del
                    <br />
                    marketing desde hace 24 años.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Nuestros docentes son referentes, con basta experiencia
                    profesional y educacional.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Nuestra currícula y temas educativos, están alineados y
                    actualizados a la necesidad de la
                    <br />
                    industria educativa actual, independientemente al sector en
                    el que hagan énfasis.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Conocemos y transferimos los mejores factores claves para
                    hacer frente a estos nuevos tiempos,
                    <br />
                    de los que cada institución educativa necesita (sean
                    escuelas, coledios, unversidades, institutos,
                    <br />
                    academias, etc.) para escalar en sus matriculaciones,
                    retenciones y fidelizaciones.
                  </li>
                </div>
                <button
                  className="boton-inscribirse-uni"
                  style={{
                    height: "max-content",
                    border: "none",
                    minWidth: "max-content",
                    maxWidth: "40%",
                    borderRadius: "30px",
                    padding: "15px 15px",
                    background: "var(--color-principal)",
                    color: "var(--color-secundario)",
                    fontSize: "1rem",
                    fontWeight: "700",
                    margin: "0 auto",
                    marginTop: "10px",
                  }}
                >
                  <a
                    href="https://campus.marketingeducativo.com/visitor_catalog_subscription/add_to_cart?item_id=229&item_type=Subscription"
                    style={{ color: "white" }}
                  >
                    ¡INSCRIBIRSE AHORA!
                  </a>
                </button>
              </ul>
              {/* <button className="boton-seccion-inscribirse">
                <a href="#container-form">¡INSCRIBIRSE AHORA!</a>
              </button> */}
            </div>
          </div>
        </div>
      </section>
      {/*     <h3 style={ {textAlign:'center', margin:100}}>En construcción, loco</h3> */}
      {/* <Formd /> */}
      {false && (
        <section className={"container-form"} id="container-form">
          <Form2co />
        </section>
      )}
      <Footer />
      <ModalCookies />
    </>
  );
};

export default AccederEs;
