import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import background from "../../images/Banner-Acceder.jpg";
import BotonDeSubir from "../common/BotonDeSubir";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import "./css/AccederPy.css";
import "./css/Nosotros.css";
import Form2co from "./Form2co";

const AccederPy = () => {
  const conversionTagGoogle = (id) => {
    window.gtag("event", "conversion", {
      send_to: "AW-10865560624/t-SsCJ6pvagDELCYjb0o",
      transaction_id: `${id}`,
    });
  };

  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Inscribirse - Marketing Educativo</title>
        <meta
          name="description"
          content={"Inscribirse - Marketing Educativo"}
        />
        <meta name="keyword" content="curso de Marketing y Ventas" />
      </Helmet>
      <BotonDeSubir />
      <SectionTitle title={"Confirmar Inscripción"} />
      <section
        className={"section-title-tipo"}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="info-container">
          {/* <img src={persona} alt="" className="img-fluid tipo" /> */}
          <div className="text">
            <span className="pago-anual">
              Pago de Inscripción Anual promocional de
            </span>
            <span className="precio">
              <span className="usd">USD 93, por tiempo limitado </span>
              <span className="precio-original">(en vez de USD 310)</span>
            </span>
            <div className="info-suscripcion">
              <ul>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Por tu suscripción, hoy, te ofrecemos 24 cursos, y a lo
                    largo de este año con tu mismo plan te sumaremos más cursos
                    de interés y calidad, y no deberás abonar nada adicional
                    para beneficiarte de los mismos.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Cada uno de ellos impactarán a tu enfoque profesional e
                    institucional.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Te brindamos calidad garantizada por 30 días, o te
                    devolvemos tu inversión.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    No improvisamos, somos lídere en el ámbito educativo,
                    tecnológico y de las ventas y del marketing desde hace 24
                    años.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Nuestros docentes son referentes, con vasta experiencia
                    profesional y educacional.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Nuestras currículas y temas educativos, están alineados a la
                    necesidad de la industria educativa actual,
                    independientemente al sector al que se enfoque.
                  </li>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="circulo-acceder"
                  />
                  <li>
                    Conocemos y transferimos los mejores factores claves para
                    hacer frente a estos nuevos tiempos, de los que cada
                    institución educativa necesita (sean escuelas, colegios,
                    universidades, institutos, academias, etc.) para escalar en
                    sus matriculaciones, retenciones y fidelizaciones.
                  </li>
                </div>
                <a
                  href="https://campus.marketingeducativo.com/visitor_catalog_subscription/add_to_cart?item_id=228&item_type=Subscription"
                  style={{
                    color: "white",
                    textAlign: "center",
                    cursor: "auto",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  /* onClick={()=>{conversionTagGoogle((new Date()).getTime())}} */
                >
                  <button
                    className="boton-inscribirse-uni"
                    style={{
                      height: "max-content",
                      border: "none",
                      minWidth: "max-content",
                      maxWidth: "40%",
                      borderRadius: "30px",
                      padding: "15px 15px",
                      background: "var(--color-principal)",
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "700",
                      margin: "0 auto",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    PAGAR
                  </button>
                </a>
              </ul>
              {/* <button className="boton-seccion-inscribirse">
                <a href="#container-form">¡INSCRIBIRSE AHORA!</a>
              </button> */}
              <p className="aceptar-terminos-privacidad">
                Al hacer clic en el botón "Pagar" aceptas nuestros <br />
                <a href="/terminos-y-condiciones" target="_blank">
                  Términos y Condiciones
                </a>{" "}
                y{" "}
                <a href="/cookies-y-privacidad" target="_blank">
                  Políticas de privacidad
                </a>
                <br />
                Consultá por otros métodos de pago{" "}
                <a href="/contacto" target="_blank">
                  escribiéndonos aquí
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*     <h3 style={ {textAlign:'center', margin:100}}>En construcción, loco</h3> */}
      {/* <Formd /> */}
      {false && (
        <section className={"container-form"} id="container-form">
          <Form2co />
        </section>
      )}
      <Footer />
      <ModalCookies />
    </>
  );
};

export default AccederPy;
