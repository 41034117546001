import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./ContactForm.css";
import paises from "../../fuentes/paises.json";

const ContactForm = (props) => {
  
  const campoPais = Object.keys(paises).map((valor,indice)=>{
    return <option value={paises[valor]} key={indice}>{paises[valor]}</option>;
  });
  console.log(campoPais);
  const [datosJson, setDatosJson] = useState(false);

  const modificarValor = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
    /* console.log(e); */
  };

  const [formulario, setFormulario] = useState({
    nombre: "",
    email: "",
    mensaje: "",
    pais: ""
  });

  const [formValido, setFormValido] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(null);
  const captcha = useRef(null);

  const enviar = async (nombre, email, mensaje, pais) => {
    const enviarFormulario = {
      nombre: nombre,
      email: email,
      mensaje: mensaje,
      pais: pais
    };

    let formBody = [];
    for (let property in enviarFormulario) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(enviarFormulario[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    /* console.log(formBody); */
    const data = await fetch(
      process.env.REACT_APP_API_PRD1 + "public/api/enviar_correo",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      }
    );

    const json_data = await data.json();
    /* console.log(data); */
    //window.alert(json_data.mesasge);
    setDatosJson(json_data);
    /* console.log(json_data); */
  };

  const enviado = (e) => {
    e.preventDefault();
    let valores = 0;
    for (const key in formulario) {
      if(formulario[key].trim() !== ""){
        valores=valores+1;
      }
    }
    


    if (captcha.current.getValue() && valores === 4) {
      //setFormValido(true);
      setCaptchaValido(true);
    } else {
      //setFormValido(false);
      setCaptchaValido(false);
    }

    if (/* formValido === true &&  */captchaValido === true) {
      enviar(formulario.nombre, formulario.email, formulario.mensaje, formulario.pais);
    } else {
      alert(
        "Lo sentimos, no hemos podido enviar el mensaje. Intentalo de nuevo más tarde."
      );
    }
  };

  const cambio = (valor) => {
    /* console.log("valor es ", valor); */
    if (captcha.current.getValue) {
      console.log("el usuario no es un robot");
      setCaptchaValido(true);
    }
  };

  return (
    <>
      <form className={"contact-form"} onSubmit={enviado}>
        <div className={"input-group"}>
          <input
            value={formulario.nombre}
            className="nombre"
            type="text"
            name={"nombre"}
            placeholder={"Nombre y apellidos"}
            required={true}
            onChange={(e) => modificarValor(e)}
          />

          <input
            value={formulario.email}
            className="email"
            type="email"
            name={"email"}
            placeholder={"Email"}
            required={true}
            onChange={(e) => modificarValor(e)}
          />
        </div>
        <div className={"input-group"}>
          <select name="pais" defaultValue={formulario.pais} onChange={(e) => modificarValor(e)}>
            <option value="" disabled="disabled" required>Seleccione su país</option>
            {campoPais}
          </select>
        </div>
        <div className={"input-group"}>
          <textarea
            value={formulario.mensaje}
            name="mensaje"
            id=""
            cols="30"
            rows="10"
            placeholder={"Mensaje"}
            required={true}
            onChange={(e) => modificarValor(e)}
          />
        </div>
        <div className="seccion-enviar">
          <button type="submit" className="btn-primary button-nosotros-form">
            Enviar
          </button>

          <div className={"recaptcha"}>
            <ReCAPTCHA
              ref={captcha}
              sitekey="6LfoYVAeAAAAAB7MtyozAnjA-IgfIwCTMmMEv5c5"
              onChange={cambio}
            />
          </div>
          {captchaValido === false && (
            <div className={"error-captcha"}>Por favor acepta el captcha</div>
          )}
        </div>

        <div
          className={datosJson !== false ? `test ${datosJson.status}` : "test "}
        >
          {datosJson !== false && datosJson.msg}
        </div>
      </form>
    </>
  );
};

export default ContactForm;
