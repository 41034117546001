import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useResponsive from "../../hooks/useResponsive";
import slide1Celular from "../../images/360x767-Mkt-Educ-.jpg";
import slide1Tablet from "../../images/670x1024-Mkt-Educ-H-sintext.jpg";
import slide1Pc from "../../images/Banner-principal.jpg";
import slide2Celular from "../../images/slide-costo-mobile.jpeg";
import slide2Pc from "../../images/slide-costo-pc.jpeg";
import slide2Tablet from "../../images/slide-costo-tablet.jpeg";
import "./Banner.css";

const Banner = () => {
  const width = useResponsive() ?? 769;
  const tablet = width >= 425 && width <= 768;
  const pc = width >= 769;

  console.log("ac", width);

  const carusel =React.useRef();
  const urlAux =React.useRef();
  const url =React.useRef();
  const url2 =React.useRef();

  const vacio = (par) =>{
    return par;
  }

  const [ancho, setAncho] = React.useState(null);
  const [dirimage, setDirimage] = React.useState(null);
  const [dirimage2, setDirimage2] = React.useState(null);
  const [dirimageaux, setDirimageaux] = React.useState(null);

  React.useEffect(() => {
    //console.log("corriente",mail.current);
    setDirimage(pc ? slide1Pc : tablet ? slide1Tablet : slide1Celular);
    setDirimage2(pc ? slide2Pc : tablet ? slide2Tablet : slide2Celular);
    setDirimageaux(pc ? slide1Pc : tablet ? slide1Tablet : slide1Celular);
    urlAux.current.src = dirimageaux;
    url.current.src = dirimage;
    url2.current.src = dirimage2;
    console.log("test", url.current.src);
    //carusel.current.itemsRef.removeChild(carusel.current.itemsRef[0]);
    delete(carusel.current.itemsRef[1]);
    console.log("carusel", carusel.current.itemsRef);
    setTimeout(() => {
      //vacio(width);
      url.current.src = dirimage;
      url2.current.src = dirimage2;
    }, 100); 
  }, []);

  React.useEffect(() => {
    //console.log("corriente",mail.current);
    setTimeout(() => {
      //vacio(width);
      setDirimage(pc ? slide1Pc : tablet ? slide1Tablet : slide1Celular);
      setDirimage2(pc ? slide2Pc : tablet ? slide2Tablet : slide2Celular);
      urlAux.current.src = pc ? slide1Pc : tablet ? slide1Tablet : slide1Celular;
      url.current.src = dirimage;
      url2.current.src = dirimage2;
    }, 100);
  }, [width,dirimage,dirimage2]);

  return (
    <>
    
    <Carousel
      showThumbs={false}
      showArrows={true}
      showIndicators={false}
      showStatus={false}
      swipeable={false}
      autoPlay={true}
      interval={4500}
      infiniteLoop={true}
      ref={carusel}
    >
      <div className="carousel-item">
        <div className={"carousel-tag tag-1"}>
          Los mejores cursos para proyectar tu centro educacional
          <br />
          <p>
            Brindamos capacitacion con las más avanzadas herramientas y técnicas
            que mejoran el proceso de promoción, captación, retención y
            fidelización de alumnos para toda institución educativa.
          </p>
        </div>
        <img
          ref={urlAux}
          src={""}
          alt={""}
          className={"dnone"}
        />
        <img
          ref={url}
          src={""}
          alt={""}
        />
      </div>
      <div className="carousel-item">
        <div className={"carousel-tag tag-2"}>
          ¡Por solo <span className="carusel-amarillo">USD 93</span>{" "}
          <span className="letra-fina">tendrás acceso</span>{" "}
          <span className="negrita">a todos nuestros cursos</span>{" "}
          <span className="carusel-amarillo">
            por un <span className="negrita">año!</span>
          </span>
        </div>
        <img
          ref={url2}
          src={""}
          alt={""}
        />
      </div>
    </Carousel>
    </>
  );
};

export default Banner;
