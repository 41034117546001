import { Helmet } from "react-helmet";
import "react-tabs/style/react-tabs.css";
import grupoIdt from "../../images/IMG-Nosotros_1.jpg";
import BotonDeSubir from "../common/BotonDeSubir";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import "./css/Nosotros.css";

const Nosotros = (props) => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Nosotros - Marketing Educativo</title>
        <meta name="description" content={"Nosotros - Marketing Educativo"} />
        <meta name="keyword" content="Marketing Educativo" />
      </Helmet>
      <BotonDeSubir />
      <SectionTitle title={"sobre nosotros"} />
      <section className={"nosotros-holder"}>
        <div className="primera-seccion-nosotros">
          <h2>¡Bienvenidos a Marketing Educativo!</h2>
          <p>
            Un servicio proveído por el <strong>Grupo IDT, LLC</strong> con
            Registro de Contribuyente 30-1276141, y el <strong>IDT S.A.</strong>{" "}
            con RUC 80084507-2. Somos un holding empresarial tecnológico
            educativo con más de 24 años de trayectoria. Nos especializamos en
            ofrecer soluciones educativas digitales disruptivas y de rápida
            formación profesional.{" "}
            <strong>
              Operamos desde EEUU y Paraguay para el resto del mundo
            </strong>
            , haciendo del conocimiento, de la pedagogía, la innovación y de la
            calidad académica a nuestros principales aliados.
          </p>
          <p>
            En el <strong>Grupo IDT</strong> nos apasionamos con el compromiso
            de formar expertos a través de las tecnologías emergentes. Contamos
            con el reconocimiento y el aval del Ministerio de Educación y
            Ciencias, como Instituto de Formación Docente (IFD) y como Instituto
            Técnico Superior (ITS)
          </p>
        </div>
        <div className="segunda-seccion-nosotros">
          <img src={grupoIdt} alt="" className="img-fluid" />
        </div>

        {/* <div className="tercera-seccion-nosotros">
          <img src={chica} alt="" className="img-fluid" />
        </div>
        <div className="cuarta-seccion-nosotros">
          <Tabs className="form-nosotros" style={{ marginTop: 30 }}>
            <TabList>
              <Tab>MARKETING EDUCATIVO</Tab>
              <Tab>CONTACTO</Tab>
            </TabList>

            <TabPanel>
              <p>
                Es un proyecto del Grupo IDT, con el cual pretendemos contribuir
                a una necesidad de formación de calidad basada en cursos
                esenciales, desde lo básico a lo avanzado en herramientas de
                ofimática en general. Pasando por Windows, Word, Excel,
                PowerPoint, hasta cursos de Scratch para niños/as que deseen
                adentrarse en el mundo de la programación (que es el futuro para
                ellos). Brindamos formación integral y de calidad, para toda la
                familia a un costo accesible.
              </p>
            </TabPanel>
            <TabPanel>
              <ContactForm />
            </TabPanel>
          </Tabs>
        </div> */}
      </section>
      <Footer />
      <ModalCookies />
    </>
  );
};

export default Nosotros;
