import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/common/ScrollToTop";
import Acceder from "./components/pages/Acceder";
import AccederEs from "./components/pages/AccederEs";
import AccederUni from "./components/pages/AccederUni";
import Blanco from "./components/pages/Blanco";
import Blog from "./components/pages/Blog";
import Carrito from "./components/pages/Carrito";
import Categorias from "./components/pages/Categoria";
import Contacto from "./components/pages/Contacto";
import CookiesYPrivacidad from "./components/pages/CookiesYPrivacidad";
import Cursos from "./components/pages/Cursos";
import FinalizacionCompra from "./components/pages/FinalizacionCompra";
import Home from "./components/pages/Home";
import Nosotros from "./components/pages/Nosotros";
import Politicas from "./components/pages/Politicas";
import Preguntas from "./components/pages/Preguntas";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Blanco />
            <Redirect to="/cursos" />
          </Route>
          <Route exact path="/cursos">
            <Home />
          </Route>
          <Route exact path="/cursos-marketing-educativo/:slug/:seo">
            <Cursos />
          </Route>
          <Route exact path="/cursos-marketing-educativo/:slug">
            <Cursos />
          </Route>
          <Route exact path="/cursos-marketing-educativo/">
            <Cursos />
          </Route>
          <Route exact path="/cursos-marketing-educativo">
            <Cursos />
          </Route>
          <Route exact path="/carrito">
            <Carrito />
          </Route>
          <Route exact path="/inscribirse/compra/:estado/:idcompra">
            <Acceder />
          </Route>
          <Route exact path="/inscribirse/compra/:estado/:idcompra">
            <Acceder />
          </Route>
          <Route exact path="/inscribirse">
            <Acceder />
          </Route>
          <Route exact path="/inscribirse-exterior">
            <AccederUni />
          </Route>
          <Route exact path="/inscribirse-espana">
            <AccederEs />
          </Route>
          <Route exact path="/contacto">
            <Contacto />
          </Route>
          <Route exact path="/sobre-nosotros">
            <Nosotros />
          </Route>
          <Route exact path="/blog/:slug">
            <Blog />
          </Route>
          <Route exact path="/blog">
            <Blog />
          </Route>
          <Route exact path="/categoria/:slug">
            <Categorias />
          </Route>
          <Route exact path="/categoria">
            <Blog />
          </Route>
          <Route exact path="/preguntas">
            <Preguntas />
          </Route>
          <Route exact path="/terminos-y-condiciones">
            <Politicas />
          </Route>
          <Route exact path="/cookies-y-privacidad">
            <CookiesYPrivacidad />
          </Route>
          <Route exact path="/finalizacion-compra">
            <FinalizacionCompra />
          </Route>
          {/* <Route exact path="/landing">
            <Landing />
          </Route> */}
          {/* <Route exact path="/landing-excel">
            <LandingExcel />
          </Route> */}
          <Route exact path="*">
            <Home />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
