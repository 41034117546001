import React from "react";
import { Link } from "react-router-dom";
import mejorarImg from "../utlis/mejorarImg";
import mejorarUrl from "../utlis/mejorarUrl";
import "./TarjetaCurso.css";

const TarjetaCurso = ({ curso }) => {
  const urlWebP = "https://www.marketingeducativo.com/image.php?url=";

  const titulo = () => {
    if (curso.title.length > 70) {
      return `${curso.title.substring(0, 70)}...`;
    }

    return curso.title;
  };

  return (
    <>
      <Link
        to={`/cursos-marketing-educativo/${curso.id}/${mejorarUrl(
          curso.title
        )}`}
        className={"invisible-link"}
        key={`movie-${curso.id}`}
      >
        <div className={"tarjeta-curso"}>
          <img
            src={urlWebP+""+mejorarImg(`${curso.images.full}`)}
            alt={`${curso.title}`}
          />
          <div className="tarjeta-curso-info">
            <h2 className={"title"}>{titulo()}</h2>
            <span>{curso.categories[0] ?? ""}</span>
          </div>
        </div>
      </Link>
    </>
  );
};

export default TarjetaCurso;
