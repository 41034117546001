import { useEffect, useState } from "react";

const useResponsive = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("ancho?", window.innerWidth);
      setWidth(window.innerWidth);
    };
    setTimeout(() =>{
      window.addEventListener("load", handleResize);
      window.addEventListener("resize", handleResize);
    }, 200)
    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return width;
};

export default useResponsive;
