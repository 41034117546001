import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import "./css/Nosotros.css";

const Carrito = (props) => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <h3 style={{ textAlign: "center", margin: 100 }}>
        En construcción, textilia
      </h3>
      <Footer />
      <ModalCookies />
    </>
  );
};

export default Carrito;
