import "./TarjetaCurso.css";
import "./TarjetaTest.css";

const TarjetaTest = ({ profesor }) => {
  return (
    <>
      <div className="tarjeta-curso tarjeta-profe">
        <img src={profesor.foto} alt="" />
        <div className="tarjeta-curso-info">
          <h2>{profesor.nombre}</h2>
          <span>{profesor.titulo}</span>
        </div>
        {profesor.descripcion ?? <p>{profesor.descripcion}</p>}
      </div>
    </>
  );
};

export default TarjetaTest;
