const ApiGuardarCache = async (name) => {
    const duration = (1000 * 60 * 60 * 24 * 5) + 1000;

    const addDays = (date, days) => {
        const result = new Date(date);
        console.log("result: ", result);
        console.log("date: ", date);
        console.log("days: ", days);
        result.setDate(result.getDate() + days);
        console.log("nuevo dia", result);
        return result;
    }
  
    const get_storage = (name) => {
      const storage = localStorage.getItem(name);
  
      if (storage === null) {
        return query_endpoint(name);
      }
      const storageObj = JSON.parse(storage);
  
      if (storageObj.nextCleanup < new Date().getTime()) {
        return query_endpoint(name);
      } else {
        return storageObj.data;
      }
    };
  
    const set_storage = async (name, data) => {
      let newStorage = {
        name: name,
        nextCleanup: new Date().getTime() + duration,
        data: data,
      };
      return localStorage.setItem(name, JSON.stringify(newStorage));
    };
  
    const query_endpoint = async (name) => {
        const ahora = new Date();
        const futuro = addDays(ahora,5);
        const data = {
            inicio: {
                segundo: ahora.getSeconds(),
                minuto: ahora.getMinutes(),
                hora: ahora.getHours(),
                dia: ahora.getDate(),
                mes: (ahora.getMonth()),
                anho: ahora.getFullYear()
            },
            fin: {
                segundo: futuro.getSeconds(),
                minuto: futuro.getMinutes(),
                hora: futuro.getHours(),
                dia: futuro.getDate(),
                mes: (futuro.getMonth()),
                anho: futuro.getFullYear()
            }
        };
        console.log("nuevo ahora ",ahora);
        console.log("nuevo futuro ",futuro);
        console.log("nuevo data ",data);
        const json_data = JSON.parse(JSON.stringify(data));
        await set_storage(name, json_data);
        return json_data;
    };
  
    return get_storage(name);
  };
  
  export default ApiGuardarCache;
  