import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/Logo-Marketing.png";
import "./Header.css";

const Header = () => {
  const [active, setActive] = useState("");
  const PATH_NAME = window.location.pathname;

  useEffect(() => {
    if (PATH_NAME === "/cursos") {
      setActive("active");
      return;
    }

    setActive("");
  }, [PATH_NAME]);

  const menu = (
    <ul>
      <NavLink activeClassName={active} to="/">
        <li>Inicio</li>
      </NavLink>
      <NavLink activeClassName="active" to="/cursos-marketing-educativo">
        <li>Cursos</li>
      </NavLink>
      <NavLink activeClassName="active" to="/preguntas">
        <li>Preguntas</li>
      </NavLink>
      <NavLink activeClassName="active" to="/blog">
        <li>Blog</li>
      </NavLink>
      <NavLink activeClassName="active" to="/sobre-nosotros">
        <li>Nosotros</li>
      </NavLink>
      <NavLink activeClassName="active" to="/contacto">
        <li>Contacto</li>
      </NavLink>
      <NavLink activeClassName="" to="/inscribirse">
        <li className={"nav-btn"}>Inscribirse</li>
      </NavLink>
    </ul>
  );

  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <header>
      <div className="container-header">
        <nav>
          <NavLink to="/">
            <img src={logo} alt="Cursos de Marketing Educativo" />
          </NavLink>
          {menu}
          <span onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </span>
        </nav>
        <section className={`mobile-menu ${open ? "open" : ""}`}>
          {menu}
        </section>
      </div>
    </header>
  );
};

export default Header;
