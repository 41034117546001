import {
  faFileAlt,
  faFlag,
  faImages,
  faVideo,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import { Link, useHistory, useParams } from "react-router-dom";
import AtrasAdelante from "../common/AtrasAdelante";
import BotonDeSubir from "../common/BotonDeSubir";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import TarjetaCurso from "../common/TarjetaCurso";
import ApiCache from "../utlis/ApiCache";
import ApiGuardarPagina from "../utlis/ApiGuardarPagina";
import mejorarImg from "../utlis/mejorarImg";
import mejorarUrl from "../utlis/mejorarUrl";
import Collapsible from "react-collapsible";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./css/Cursos.css";

const Cursos = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});

  const [curriculum, setCurriculum] = useState([]);
  const [faq, setFaq] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const [view, setView] = useState("all");
  const [detalleContent, setDetalleContent] = useState("");
  const [cursosPopulares, setCursosPopulares] = useState([]);

  let { slug } = useParams();

  const divRef = useRef();

  const endpoint =
    process.env.REACT_APP_API_LMS + `courses?page=1&per_page=1000`;
  //console.log(endpoint);
  const endpointSingle = process.env.REACT_APP_API_LMS + `course?id=${slug}`;

  //console.log(endpointSingle);

  const shuffle = (a) => {
    let j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  };
  const history = useHistory();
  let iframeDetalleRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (slug === undefined) {
      ApiCache("todos-cursos", endpoint)
        .then((r) => {
          setCourse({});
          setFaq([]);
          setCurriculum([]);
          setMetaInfo([]);
          setCursosPopulares([]);
          setCourses(r.courses);
          setIsLoading(false);
          setView("all");
        })
        .catch((err) => console.log(err));
    } else {
      ApiCache(`curso-${slug}`, endpointSingle)
        .then((r) => {
          /* console.log("simple r:",r);
          console.log("simple r con status:",r.status); */
          if (r.code !== "invalid_course_id") {
            setCourses([]);
            setCourse(r);
            setFaq(r.faq);
            setCurriculum(r.curriculum);
            setMetaInfo(r.categories_object);
            setIsLoading(false);
            setView("single");
            /* console.log(divRef.current); */
            setTimeout(() => {
              ApiGuardarPagina(
                "curso",
                r.id,
                mejorarUrl(r.title),
                divRef.current.ownerDocument?.documentElement?.innerHTML
              );
            }, 3000);
          } else {
            window.location.href = "/cursos-marketing-educativo";
            history.push("/cursos-marketing-educativo");
          }
        })
        .catch((err) => {
          /* console.log(err);console.log("test de error"); */ window.location.href =
            "/cursos-marketing-educativo";
          history.push("/cursos-marketing-educativo");
        });

      ApiCache("todos-cursos", endpoint)
        .then((r) => {
          shuffle(r.courses);
          setCursosPopulares(r.courses);
        })
        .catch((err) => console.log(err));
    }
  }, [slug, endpointSingle, endpoint]);

  let ordenarCursos = {
    categ_146: [],
    categ_147: [],
  };
  ordenarCursos.categ_146 = courses.filter((curso) => {
    return curso.categories_object.find((el) => el.id === 146);
  });
  ordenarCursos.categ_147 = courses.filter((curso) => {
    return curso.categories_object.find((el) => el.id === 147);
  });

  const cursos = ordenarCursos.categ_147.concat(ordenarCursos.categ_146);

  /* console.log("ordenarCursos",ordenarCursos);
  console.log("cursos",cursos); */

  /* const items = courses.map((curso) => { */
  const items = cursos.map((curso) => {
    return <TarjetaCurso curso={curso} key={`cursos-todos-${curso.id}`} />;
  });

  const getIconCurriculum = (name) => {
    switch (name) {
      case "slide":
        return <FontAwesomeIcon icon={faImages} />;
      case "text":
        return <FontAwesomeIcon icon={faFileAlt} />;
      case "video":
        return <FontAwesomeIcon icon={faVideo} />;
      case "Section 1":
        return <FontAwesomeIcon icon={faFlag} />;
      default:
        return <FontAwesomeIcon icon={faFileAlt} />;
    }
  };

    const itemsCurriculum = curriculum.map((cv, index) => {
    return (
      <div className={"curriculum-row"} key={`curriculum-${index}`}>
        <div className={"curriculum-number"}>{index + 1}</div>
        <div className={"curriculum-icon"}>{getIconCurriculum(cv.view)}</div>
        <div className={"curriculum-label"}>{cv.label}</div>
        <div className={"curriculum-duration"}>
          {cv.duration != null && (
            <>
              <FontAwesomeIcon icon={faClock} /> {cv.duration}
            </>
          )}
        </div>
      </div>
    );
  });

  const itemsDetalle = faq.map((detalle, index) => {
    return (
      <Collapsible
        key={`collapsible-detalle-${index}`}
        trigger={detalle.question}
      >
        {detalle.answer}
      </Collapsible>
    );
  });

  const itemsMeta = metaInfo.map((info, index) => {
    return (
      <Link key={`info-${index}`} to={"#"}>
        {info.name}
      </Link>
    );
  });

  const setDetalleToState = () => {
    setDetalleContent(
      iframeDetalleRef.current.contentWindow.document.body.innerHTML
    );
  };

  const urlWebP = "https://www.marketingeducativo.com/image2.php?url=";

  const buildCursosPopulares = cursosPopulares.map((item, index) => {
    if (index < 5) {
      return (
        <Link
          to={`/cursos-marketing-educativo/${item.id}/${mejorarUrl(
            item.title
          )}`}
          className={"invisible-link"}
          key={`popular-${item.id}`}
        >
          <div className={"curso-popular-item"}>
            <div className={"img-squared"}>
              <img src={item.images.small} alt={item.title} />
            </div>
            <h2 className={"title"}>{item.title}</h2>
          </div>
        </Link>
      );
    } else {
      return <i key={"notanusefullelement-" + index} />;
    }
  });

  if (isLoading) {
    return (
      <>
        <MensajeDescuento />
        <BotonDeSubir />
        <Header />
        <SectionTitle title={" "} />
        <div className="container">
          <Loader
            className={"mainLoader"}
            type="Rings"
            color="#3b6075"
            height={100}
            width={100}
          />
        </div>
        <Footer />
        <ModalCookies />
      </>
    );
  }

  if (view === "all" && isLoading === false) {
    return (
      <>
        <MensajeDescuento />
        <BotonDeSubir />
        <Header />
        <Helmet>
          <title>Cursos de Marketing Educativo y Marketing Digital</title>
          <meta
            name="description"
            content={
              "Cursos de captación, retención, fidelización, PEI, programas de incentivos, bonificaciones, plan de comunicación, precios, marketing digital, y mucho más."
            }
          />
          <meta name="keyword" content="Cursos de Marketing" />
        </Helmet>
        <SectionTitle title={"cursos-habilitados"} />

        <div className={"container all-cursos"}>{items}</div>
        <Footer />
        <ModalCookies />
      </>
    );
  }

  if (view === "single" && isLoading === false) {
    //console.log("traigo: " + course?.images?.full);
    return (
      <div className="tee" ref={divRef}>
        <MensajeDescuento />
        <BotonDeSubir />
        <Header />
        <SectionTitle title={course.title} />
        <Helmet>
          <title>
            {course.title ?? "Sin Título"} - Cursos de Marketing Educativo
          </title>
          <meta
            name="description"
            content={course.title + " - Cursos de Marketing Educativo"}
          />
          <meta name="keyword" content="Cursos de Marketing" />
        </Helmet>
        <div className="container curso-single">
          <main>
            {/* <h2>{course.title}</h2>
            <section className={"meta-categorias"}>
              <div className="icon">
                <FontAwesomeIcon icon={faBookmark} />
              </div>
              <div>
                <h3>Categorias:</h3>
                {itemsMeta}
              </div>
            </section> */}

            <div className="single-tabs">
              <Tabs style={{ marginTop: 30 }}>
                <TabList>
                  <Tab>Descripción</Tab>
                  {/* <Tab>Curriculum</Tab> */}
                  <Tab>Contenido</Tab>
                </TabList>
              {/* Por cosas de la vida, esta fue la mejor forma que encontre de parsear el html de la respuesta */}
                <TabPanel>
                  <div
                    id={"div-detalle"}
                    dangerouslySetInnerHTML={{ __html: detalleContent }}
                  />
                  <img
                    src={urlWebP + "" + mejorarImg(course?.images?.full)}
                    className="img-fluid"
                    alt={""}
                  />
                  <iframe
                    srcDoc={course?.description}
                    onLoad={setDetalleToState}
                    title={"i"}
                    ref={iframeDetalleRef}
                    className={"iframe-ocultar"}
                  />
                </TabPanel>
                {/* <TabPanel>{itemsCurriculum}</TabPanel> */}
                <TabPanel>{itemsDetalle}</TabPanel>
              </Tabs>
            </div>
            {/* <div style={{textAlign: 'center'}}>
              <div style={{marginLeft: '-10px'}}>
              <br />
              <Link to={"/inscribirse"} style={{background: 'var(--color-principal)', color: 'white', border: '2px solid var(--color-principal', padding: '5px 10px', width: '181px', display: 'block', margin: '0 auto'}} >
              Inscribirse
            </Link>
              </div>
            </div> */}
            <AtrasAdelante
              publicacion={course}
              tipo={"cursos-marketing-educativo"}
            />
          </main>

          <aside>
            <Link to={"/inscribirse"} className={"btn-sidebar btn-inscribirse"}>
              Inscribirse
            </Link>
            <a
              href="https://campus.marketingeducativo.com/?from=%2Fadmin_dashboard&log_in_required=true"
              className={"btn-sidebar"}
            >
              Iniciar Sesión
            </a>
            {/* <ul>
              <li key={"list1"}>
                <div>
                  Inscriptios: <b>865 students</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faUsers} />
                </div>
              </li>
              <li key={"list2"}>
                <div>
                  Duración: <b>6 hs.</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </li>
              <li key={"list3"}>
                <div>
                  Conferencias: <b>8</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faBullhorn} />
                </div>
              </li>

              <li key={"list4"}>
                <div>
                  Video: <b>2</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faVideo} />
                </div>
              </li>

              <li key={"list5"}>
                <div>
                  Nivel: <b>Intermediario</b>
                </div>
                <div className={"icon"}>
                  <FontAwesomeIcon icon={faChartBar} />
                </div>
              </li>
            </ul> */}
            <div className="cursos-populares">
              <h2 className={"title"}>Cursos Recomendados</h2>
              {buildCursosPopulares}
            </div>
          </aside>
        </div>

        <Footer />
        <ModalCookies />
      </div>
    );
  }
};

export default Cursos;
