const ApiGuardarPagina = async (tipo, id, titulo, html) => {
    const pagina = {
        tipo: tipo,
        id: id,
        titulo: titulo,
        html: html
    };
    const data = await fetch(
        //process.env.REACT_APP_BC_URL,
        "https://marketingeducativo.com/guardarEn.php",
        {
          method: "POST",
          /* mode: "cors", */
          headers: {
            //Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pagina),
        }
      );
  
      const json_data = await data.json();
}

export default ApiGuardarPagina;