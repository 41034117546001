import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/es-mx";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import AtrasAdelante from "../common/AtrasAdelante";
import BotonDeSubir from "../common/BotonDeSubir";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import ApiCache from "../utlis/ApiCache";
import mejorarTituloBlog from "../utlis/mejorarTituloBlog";
import "./css/Blog.css";

const urlWebP = "https://www.marketingeducativo.com/image2.php?url=";

const mejorarImg = (imgx) => {
  if (imgx === null || imgx === "") {
    /* return { CursosDeOfimatica }; */
    return "";
  } else {
    //console.log(imgx);
    let imgx2 = imgx.split("-");
    //console.log(imgx2);
    const ultimo = imgx2[imgx2.length - 1];
    const vectorExtension = ultimo.split(".");
    imgx2.pop();
    //console.log(imgx2.join("-") + "." + vectorExtension[1]);
    return `${imgx2.join("-")}.${vectorExtension[1]}`;
  }
};

const Blog = (props) => {
  const imagenes = [];
  const cantporpagina = 10;
  const [pageCount, setPageCount] = useState(5);
  const [posts, setPosts] = useState([]),
    [posts_aux, setPostsAux] = useState([]),
    [post, setPost] = useState({}),
    [isLoading, setIsLoading] = useState(true),
    [page, setPage] = useState(1),
    [pagina, setPagina] = useState(0),
    [filters, setFilters] = useState(""),
    [cantidad, setCantidad] = useState(0),
    [isSingle, setIsSingle] = useState(false);

  let { slug } = useParams();
  const locale = "es";

  const limpiar = (texto) => {
    return texto
      .replace("<p>", "")
      .replace("</p>", "")
      .replace("&hellip;", "...");
  };

  for (let index = 0; index < localStorage.length; index++) {
    if (localStorage.key(index) !== "tiempo") {
      /* console.log("item de localstorage:",localStorage.key(index)); */
      localStorage.removeItem(localStorage.key(index));
    }
  }

  const endpoint = process.env.REACT_APP_API_WP + `posts?page=${page}&_embed`,
    endpointBusqueda =
      process.env.REACT_APP_API_WP +
      `search?search=${filters}&per_page=50&page=${page}&subtype=post&_embed`,
    endpointSingle = process.env.REACT_APP_API_WP + `posts?slug=${slug}&_embed`;
  //console.log("endpoint: " + endpoint);
  //console.log("endpointSingle: " + endpointSingle);

  const [buscado, setBuscado] = useState("");

  //posts.map((p) => { return p})

  /*const testt = async () => {
        let w = await fetch(endpoint);
        const poster = w.headers.get('x-wp-total');
        console.log(poster);
        setCantidad(poster);
        setPageCount(Math.ceil(cantidad/cantporpagina));
    }*/

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.info("pagina seleccionada: " + selectedPage);
    /*if(selectedPage === null || selectedPage === 0){
            setPage(1);
            setPagina(page - 1);
        }else{
            setPagina(selectedPage);
            setPage(pagina + 1);
        }*/
    if (selectedPage === null) {
      setPage(1);
      setPagina(page - 1);
    } else {
      setPagina(selectedPage);
      setPage(selectedPage + 1);
    }
    console.info("pagina seleccionada (page): " + page);
    console.info("pagina seleccionada (pagina): " + pagina);
  };

  //testt();

  function buscar() {
    //alert('test bro' + buscado);
    if (buscado !== "") {
      setIsSingle(false);
      setPosts([]);
      //setIsLoading(true);
      //setIsLoading(false);
      setFilters(buscado);
      //setPosts(posts_aux)
      //console.log("buscado");
      //setFilters('1');
    }
  }

  /**/
  /*const handlePageClick = (e) => {
        console.log(e);

    };*/
  /**/

  useEffect(() => {
    setPage(page);
    setFilters(filters);
    const testt = async () => {
      let w = await fetch(endpoint);
      const poster = w.headers.get("x-wp-total");
      /*console.log("Que loco bro");
      console.log(poster);*/
      setCantidad(poster);
      setPageCount(Math.ceil(cantidad / cantporpagina));
    };
    testt();
    moment.locale(locale);

    setIsLoading(true);

    if (slug === undefined) {
      /*console.log("slug es unndefined!");
      console.log("page: " + page);
      console.log("filters: " + filters);*/
      setIsSingle(false);
      if (filters === "") {
        ApiCache(`todos-blogs-page-${page}-filters-${filters}`, endpoint)
          .then((r) => {
            //console.log("soy la 'R', que mas queres");
            //console.log(r);
            setPosts(r);
            setPostsAux([r[1], r[2]]);
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        ApiCache(
          `todos-blogs-page-${page}-filters-${filters}`,
          endpointBusqueda
        )
          .then((r) => {
            //console.log("soy la 'R', que mas queres, pero filtrada");
            //console.log(r);
            setPosts(r);
            //setPostsAux([r[1],r[2]]);
            setIsLoading(false);
            //console.log("Veamos el _embedded");
            //console.log(r[0]._embedded.self[0].id)
          })
          .catch((err) => console.log(err));
      }
      /*ApiCache(`todos-blogs-page-${page}-filters-${filters}`, endpoint)
                .then((r) => {
                    console.log("soy la 'R', que mas queres")
                    console.log(r);
                    setPosts(r);
                    setPostsAux([r[1],r[2]]);
                    setIsLoading(false);
                })
                .catch((err) => console.log(err))*/
      /*ApiCache(`todos-blogs-page-${page}-filters-${filters}`, endpoint)
                .then((r) => {
                    console.log("soy la 'R', que mas queres")
                    console.log(r);
                    setPosts(r);
                    setPostsAux([r[1],r[2]]);
                    setIsLoading(false);
                })
                .catch((err) => console.log(err))*/
    } else {
      //console.log("slug no es unndefined!");
      setIsLoading(true);
      setIsSingle(true);
      ApiCache(`single-blog-${slug}`, endpointSingle)
        .then((r) => {
          setPost(r[0]);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [
    slug,
    endpoint,
    endpointSingle,
    endpointBusqueda,
    cantidad,
    pageCount,
    filters,
    page,
  ]);
  /*
    slug, endpoint, endpointSingle, filters, page */

  if (isLoading) {
    return (
      <>
        <MensajeDescuento />
        <Header />
        <SectionTitle title={"Cargando.."} />
        <div className="container">
          <Loader
            className={"mainLoader"}
            type="Rings"
            color="#3b6075"
            height={100}
            width={100}
          />
        </div>
        <Footer />
        <ModalCookies />
      </>
    );
  }

  if (isSingle) {
    //console.log(post);
    return (
      <>
        <MensajeDescuento />
        <Header />
        <Helmet>
          <title>
            {mejorarTituloBlog(post?.title?.rendered) ??
              "" + " - Marketing Educativo"}
          </title>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta property="og:locale" content="es_ES" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={post?.title?.rendered ?? "" + " - Marketing Educativo"}
          />
          <meta
            property="og:description"
            content={limpiar(post?.excerpt?.rendered)}
          />
          <meta
            property="og:url"
            content="https://www.cursosdeofimatica.com/disminuir-tamano-de-un-archivo-en-excel/"
          />
          <meta
            property="og:site_name"
            content="Cursos de Marketing y Ventas"
          />
          <meta
            property="article:published_time"
            content={post?.date_gmt ?? ""}
          />
          <meta
            property="article:modified_time"
            content={post?.modified_gmt ?? ""}
          />
          <meta
            property="og:image"
            content="https://www.cursosdeofimatica.com/wp-content/uploads/2021/05/proteccion-online-3-767x587.png"
          />
          <meta property="og:image:width" content="768" />
          <meta property="og:image:height" content="588" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Escrito por" />
          <meta
            name="twitter:data1"
            content={post?._embedded?.author[0]?.name ?? ""}
          />
          <meta name="twitter:label2" content="Tiempo de lectura" />
          <meta name="twitter:data2" content="3 minutos" />
          <meta
            name="description"
            content={
              limpiar(post?.excerpt?.rendered) +
              "- Cursos de Marketing y Ventas"
            }
          />
          <meta
            name="keyword"
            content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
          />
        </Helmet>
        {/*post?.yoast_head ?? "XX"*/}
        <SectionTitle
          title={mejorarTituloBlog(post?.title?.rendered) ?? " sin titulo"}
        />
        {/* <BreadCrumbs
          path={[
            {
              id: 0,
              label: "Cursos de Marketing y Ventas",
              link: "/",
              current: false,
            },
            {
              id: 1,
              label: "Blog",
              link: "/blog",
              current: false,
            },
            {
              id: 2,
              label: //'test'  post?._embedded["wp:term"][0][0]?.name,
              link:
                "/categoria/" +
                  //'test' post?._embedded["wp:term"][0][0]?.name ?? "?",
              current: false,
            },
            {
              id: 3,
              label: post?.title?.rendered,
              link: null,
              current: true,
            },
          ]}
        /> */}
        <div className="container blog-container">
          <main>
            {/* <h2 className="post-title">{post?.title?.rendered}</h2> */}
            <div className="post-meta">
              <FontAwesomeIcon icon={faClock} />
              {moment(post?.date).format("LL")}
            </div>

            <img
              src={
                `${urlWebP}` +
                  post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                    "img-1170-500"
                  ]?.source_url ?? "default.jpg"
              }
              alt=""
              className={"img-fluid"}
            />
            <div
              className="post-content"
              dangerouslySetInnerHTML={{
                __html: post?.content?.rendered ?? "",
              }}
            />
            <div className="social-share">
              <FacebookShareButton
                url={`https://marketingeducativo.com/blog/${post?.slug}`}
                title={mejorarTituloBlog(post?.title?.rendered)}
              >
                <FacebookIcon size={32} round={false} />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://marketingeducativo.com/blog/${post?.slug}`}
                title={mejorarTituloBlog(post?.title?.rendered)}
                hashtags={["marketingeducativo", "grupoidt"]}
              >
                <TwitterIcon size={32} round={false} />
              </TwitterShareButton>
              <WhatsappShareButton
                url={`https://marketingeducativo.com/blog/${post?.slug}`}
                title={mejorarTituloBlog(post?.title?.rendered)}
                image={
                  post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                    "img-1170-500"
                  ]?.source_url
                }
              >
                <WhatsappIcon size={32} round={false} />
              </WhatsappShareButton>
              <EmailShareButton
                url={`https://marketingeducativo.com/blog/${post?.slug}`}
                subject={mejorarTituloBlog(post?.title?.rendered)}
                body={`https://marketingeducativo.com/blog/${post?.slug}`}
              >
                <EmailIcon size={32} round={false} />
              </EmailShareButton>
            </div>
            {/*
                        todo: tal vez esto..
                        <div className="social-share">
                            <span>Compartir: </span>
                        </div>*/}
            <AtrasAdelante publicacion={post} tipo={"blog"} />
          </main>
          <aside>
            <Sidebar />
            <Buscador
              setBuscado={setBuscado}
              buscar={buscar}
              buscado={buscado}
            />
          </aside>
        </div>
        <Footer />
        <ModalCookies />
      </>
    );
  } else {
    //console.log(posts);

    const publicaciones = posts.map((x) => {
      let new_slug = x.slug;
      if (filters !== "") {
        const image =
          process.env.REACT_APP_API_WP +
          `media/${x._embedded.self[0].featured_media}`;
        /* console.log("soy iamgen: " + image); */
        fetch(image)
          .then((response) => response.json())
          .then((data) => {
            imagenes[x.id] =
              data?.media_details?.sizes["img-480-380"]?.source_url ?? "";
            /* console.log(
              "la imagen es:" +
                data?.media_details?.sizes["img-480-380"]?.source_url
            ); */
            /* console.log("la imagen en posicion es: " + imagenes[x.id]);
            console.log(imagenes); */
            document.getElementById(x.id).src = imagenes[x.id];
          });
        new_slug = x._embedded.self[0].slug;
        /* console.log("Sere el new_slug: " + new_slug); */
        //x.image[x.id] = '5555';
        //console.log(x);
      }

      const formatFechaDia = (fecha, medida) => {
        const meses = [
          "DIC",
          "ENE",
          "FEB",
          "MAR",
          "ABR",
          "MAY",
          "JUN",
          "JUL",
          "AGO",
          "SEP",
          "OCT",
          "NOV",
          "DIC",
        ];
        const fechaDia = fecha.split("T");
        //console.log(fechaDia);
        const medidaTiempo = fechaDia[0].split("-");
        if (medida === "M") {
          return meses[parseInt(medidaTiempo[1])];
        } else if (medida === "D") {
          return medidaTiempo[2];
        }
      };

      return (
        <Link key={"sidebar-" + x.id} to={`/blog/${new_slug /*x.slug*/}`}>
          <section className="card-container">
            <div className="post-item">
              <img
                id={x.id}
                src={
                  urlWebP +
                  "" +
                  mejorarImg(
                    filters !== ""
                      ? ""
                      : x?._embedded["wp:featuredmedia"][0]?.media_details
                          ?.sizes["img-480-380"]?.source_url ?? "default.jpg"
                  )
                }
                alt={x.title.rendered}
              />
            </div>
            <div className="content-container">
              <div className="fecha">
                <span className="fecha__numero">
                  {filters !== ""
                    ? formatFechaDia(x._embedded.self[0].date, "D")
                    : formatFechaDia(x?.date, "D")}
                </span>
                <span className="fecha__iniciales">
                  {filters !== ""
                    ? formatFechaDia(x._embedded.self[0].date, "M")
                    : formatFechaDia(x?.date, "M")}
                </span>
              </div>
              <div>
                <div className="content">
                  <h2 className={"title-blog"}>
                    {" "}
                    {filters !== ""
                      ? x.title
                      : mejorarTituloBlog(x.title.rendered)}
                  </h2>
                  <p className={"font-black"}>
                    {filters !== ""
                      ? limpiar(x._embedded.self[0].excerpt.rendered)
                      : limpiar(x.excerpt.rendered)}
                  </p>
                </div>
                <div className="separador"></div>
              </div>
            </div>
          </section>
        </Link>
      );
    });
    /* console.log(publicaciones);
    console.log("soy la pagina" + page); */

    return (
      <div>
        <MensajeDescuento />
        <Header />
        <Helmet>
          <title>Blog - Cursos de Marketing y Ventas</title>
          <meta name="description" content="Cursos de Marketing y Ventas" />
          <meta name="keyword" content="Cursos de Marketign Educativo" />
        </Helmet>
        <BotonDeSubir />
        <SectionTitle title={"Blog"} />

        <div className="container-publicaciones">
          <main className="main-section">{publicaciones}</main>
          <aside>
            <Sidebar />
            <Buscador
              setBuscado={setBuscado}
              buscar={buscar}
              buscado={buscado}
            />
          </aside>
          <div className="container-pagination">
            {posts.length > 0 && cantidad > 10 && filters === "" && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                /*initialPage={0}*/
                forcePage={pagina}
              />
            )}
          </div>
        </div>
        <Footer />
        <ModalCookies />
      </div>
    );
  }
};

const Sidebar = (props) => {
  /* const endpointPosts =
    process.env.REACT_APP_API_WP + "posts?per_page=2&_embed"; */
  const endpointPosts = process.env.REACT_APP_API_WP + `posts?page=1&_embed`;
  //const endpointPostsSearch = process.env.REACT_APP_API_WP + 'search?search='+buscado+'&per_page=50&page=1';
  const [posts, setPosts] = useState([]),
    [isLoading, setIsLoading] = useState(true);
  let isMounted = true;
  useEffect(() => {
    setIsLoading(true);
    /* ApiCache(`blog-sidebar-last`, endpointPosts) */
    ApiCache(`todos-blogs-page-1-filters-`, endpointPosts)
      .then((r) => {
        if (isMounted) {
          const test = r;

          setPosts(
            test.filter((post, index) => {
              return index === 0 || index === 1;
            })
          );
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      isMounted = false;
    };
  }, [endpointPosts]);

  const recientes = posts.map((post) => {
    return (
      <Link key={"sidebar-" + post.id} to={`/blog/${post.slug}`}>
        <div className="sidebar-post-item">
          <div className="img-squared">
            <img
              src={`${urlWebP}${mejorarImg(
                post?._embedded["wp:featuredmedia"][0]?.media_details?.sizes[
                  "thumbnail"
                ]?.source_url ?? "default.jpg"
              )}`}
              alt={post.title.rendered}
            />
          </div>
          <h2 className={"title sidebar__titulo--mayuscula"}>
            {" "}
            {mejorarTituloBlog(post.title.rendered)}
          </h2>
        </div>
      </Link>
    );
  });
  /* console.log("recientes:");
  console.log(recientes); */
  return (
    <>
      <div className="sidebar-section first-section-sidebar">
        <div className="sidebar-decorator" />
        <h2>Recientes</h2>

        {recientes}
      </div>

      {/* <div className="sidebar-section">
                <h2>Buscador</h2>
                <div className="sidebar-search">
                    <input type="text" placeholder='buscar' onChange={(e) => {setBuscado(e.target.value)}} />
                    <button onClick={buscar}><FontAwesomeIcon icon={faSearch} /></button>
                </div>
            </div> */}

      {/*
            <div className="sidebar-section">
                <h2>Archivo</h2>
                <div className="sidebar-search">
                    <select name="search">
                        <option value="all">
                            Elegir mes
                        </option>
                    </select>
                </div>
        </div>

        {/*} <div className="sidebar-section">
            <div className="sidebar-decorator" />
            <h2>Archivo</h2>
            <div className="sidebar-search">
                <select name="search">
                    <option value="all">
                        Elegir mes
                    </option>
                </select>
            </div>
            */}
    </>
  );
};

const Cabecera = () => {
  return <meta name="keyword" content="text" />;
};

const Buscador = ({ setBuscado, buscar, buscado }) => {
  return (
    <div className="sidebar-section">
      <h2>Buscador</h2>
      <div className="sidebar-search">
        <input
          type="text"
          placeholder="Buscar"
          value={buscado}
          onChange={(e) => {
            setBuscado(e.target.value);
          }}
        />
        <button onClick={buscar}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </div>
  );
};

export default Blog;
