import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { DataContext } from "../../Context/DataContext";
import "./MensajeDescuento.css";

const MensajeDescuento = () => {
  const { mensajeVisible, setMensajeVisible } = useContext(DataContext);

  return (
    <div className="mensaje-container" style={{ display: mensajeVisible }}>
      <span className="mensaje">
        Oferta especial por tiempo limitado: Los 24 cursos por tan solo{" "}
        <span>USD 93</span> <span>(en vez de USD 310)</span>
      </span>
      <FontAwesomeIcon
        icon={faTimesCircle}
        onClick={() => setMensajeVisible("none")}
        className="mensaje-icon"
      />
    </div>
  );
};

export default MensajeDescuento;
