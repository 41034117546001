import { Helmet } from "react-helmet";
import BotonDeSubir from "../common/BotonDeSubir";
import ContactForm from "../common/ContactForm";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import ModalCookies from "../common/ModalCookies";
import SectionTitle from "../common/SectionTitle";
import "./css/Contacto.css";

const Contacto = (props) => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Contacto - Marketing Educativo</title>
        <meta name="description" content={"Contacto - Marketing Educativo"} />
        <meta
          name="keyword"
          content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
        />
      </Helmet>
      <BotonDeSubir />
      <SectionTitle title={"CONTACTANOS"} />
      <div className="container" style={{ marginTop: 50, marginBottom: 50 }}>
        <h2 style={{ textAlign: "center" }} className="titulo-formulario">
          Formulario de Contacto
        </h2>
        <div className={"contacto-holder"}>
          <ContactForm />
        </div>
      </div>
      <Footer />
      <ModalCookies />
    </>
  );
};

export default Contacto;
