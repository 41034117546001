const ApiCache = async (name, endpoint) => {
  const duration = name !== "datos-ip" ? 1000 * 60 * 15 : 1000 * 60 * 60 * 24 * 2;
  /* console.log("name", name, "duracion", duration); */
  const get_storage = (name, endpoint) => {
    const storage = localStorage.getItem(name);

    if (storage === null) {
      return query_endpoint(name, endpoint);
    }
    const storageObj = JSON.parse(storage);
    /* console.log("hora", new Date().getTime()); */
    if (storageObj.nextCleanup < new Date().getTime()) {
      for (let index = 0; index < localStorage.length; index++) {
        if(localStorage.key(index) !== "tiempo"){
          /* console.log("item de localstorage:",localStorage.key(index)); */
          localStorage.removeItem(localStorage.key(index));
        }
      }
      return query_endpoint(name, endpoint);
    } else {
      return storageObj.data;
    }
  };

  const set_storage = async (name, data) => {
    let newStorage = {
      name: name,
      nextCleanup: new Date().getTime() + duration,
      data: data,
    };
    return localStorage.setItem(name, JSON.stringify(newStorage));
  };

  const query_endpoint = async (name, endpoint) => {
    const data = await fetch(endpoint);
    const json_data = await data.json();
    localStorage.removeItem(name);
    await set_storage(name, json_data);
    return json_data;
  };

  return get_storage(name, endpoint);
};

export default ApiCache;
