import { Helmet } from "react-helmet";
import Footer from "../common/Footer";
import Header from "../common/Header";
import MensajeDescuento from "../common/MensajeDescuento";
import SectionTitle from "../common/SectionTitle";
import "./css/CookiesYPrivacidad.css";

const CookiesYPrivacidad = () => {
  return (
    <>
      <MensajeDescuento />
      <Header />
      <Helmet>
        <title>Política de cookies y de privacidad</title>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={"Política de cookies y de privacidad - Marketing Educativo"}
        />
        <meta
          property="og:description"
          content={"Terminos y Condiciones Generales"}
        />
        <meta
          property="og:url"
          content="https://www.cursosdeofimatica.com/terminos-y-condiciones/"
        />
        <meta property="og:site_name" content="Cursos de Marketing y Ventas" />
        <meta property="article:published_time" content={""} />
        <meta property="article:modified_time" content={""} />
        <meta
          property="og:image"
          content="https://contenido.mktyventas.com/wp-content/uploads/2021/05/proteccion-online-3-767x587.png"
        />
        <meta property="og:image:width" content="768" />
        <meta property="og:image:height" content="588" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Escrito por" />
        <meta name="twitter:data1" content={"GRUPO IDT S.A."} />
        <meta name="twitter:label2" content="Tiempo de lectura" />
        <meta name="twitter:data2" content="30 minutos" />
        <meta
          name="description"
          content={
            "Politicas de privacidad y reembolso - Cursos de Marketing y Ventas"
          }
        />
        <meta
          name="keyword"
          content="curso de office, curso, word, excel, powerpoint, scratch, certificacion"
        />
      </Helmet>
      <SectionTitle title="Política de Cookies y de Privacidad" />
      <section className={"container"}>
        <div className={"post-content"}>
          <p className="has-text-align-center">
            <strong>¿Qué son las cookies?</strong>
          </p>
          <p>
            Las cookies son pequeños archivos planos guardados por su navegador
            mientras navega por los sitios web. Se pueden usar para almacenar,
            recopilar y almacenar datos sobre sus actividades en los sitios web.
            Las cookies también nos permiten recordar cosas sobre sus visitas a
            nuestro sitio web, como su moneda de preferencia, y hacer que sea
            más fácil de usar.
          </p>

          <p className="has-text-align-center">
            <strong>¿Por qué el sitio usa cookies?</strong>
          </p>
          <p>
            Usamos cookies y tecnologías similares, píxeles para entregar, medir
            y mejorar nuestros servicios y experiencia de usuario de varias
            maneras. Utilizamos estas cookies cuando visita nuestro sitio web a
            través de un navegador. A medida que adoptamos tecnologías
            adicionales, también podemos recopilar datos adicionales a través de
            otros métodos.
          </p>
          <p>Usamos cookies para los siguientes propósitos: </p>
          <ul>
            <li>Autenticación y seguridad</li>
            <li>Preferencias del sitio</li>
            <li>Analítica e investigación</li>
            <li>Publicidad</li>
          </ul>
          <p>
            También trabajamos con herramientas analíticas como Google
            Analytics, Facebook Pixel, entre otros, que utilizan cookies y
            tecnologías similares para ayudarnos a comprender y analizar cómo
            los usuarios usan nuestro sitio y servicios. Esos proveedores de
            servicios pueden recopilar esos datos ellos mismos o nosotros
            podemos divulgarlos.
          </p>

          <p className="has-text-align-center">
            <strong>¿Cuáles son mis opciones de privacidad?</strong>
          </p>
          <p>
            Tiene varias opciones para controlar o limitar la forma en que
            nosotros y nuestros socios utilizamos las cookies:
          </p>
          <p>
            La mayoría de los navegadores aceptan de forma automática las
            cookies, pero puede cambiar la configuración de su navegador para
            desactivar/bloquear/rechazar las cookies, puede consultar artículos
            de soporte de su navegador. Si decide rechazar las cookies, tenga en
            cuenta que es posible que no pueda iniciar sesión, personalizar o
            utilizar algunas funciones interactivas en nuestro sitio web.
          </p>
          <p>
            Para obtener información y controlar las cookies utilizadas para
            publicidad personalizada de las empresas participantes, consulte las
            páginas de exclusión voluntaria para la Iniciativa publicitaria en
            red y la Alianza publicitaria digital, o si se encuentra en la Unión
            Europea, visite el sitio Su elección en línea. Para inhabilitar la
            publicidad gráfica de Google Analytics o personalizar los anuncios
            de la Red de Display de Google, visite la página Configuración de
            Anuncios de Google.
          </p>
          <p>
            Puede optar por excluirse de algunos de estos servicios a través de
            herramientas como el complemento de exclusión de navegador de Google
            Analytics y la cookie de exclusión de Hotjar.
          </p>
          <p>
            Para obtener información general sobre cómo orientar las cookies y
            cómo deshabilitarlas, visite{" "}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noreferrer"
            >
              www.allaboutcookies.org
            </a>
          </p>

          <p className="has-text-align-center">
            <strong>Actualizaciones e información de contacto</strong>
          </p>
          <p>
            De vez en cuando, podemos actualizar esta Política de cookies. Si lo
            hacemos, le notificaremos publicando la política en nuestro sitio.
            Si hacemos algún cambio importante, tomaremos medidas razonables
            para notificarle con anticipación sobre el cambio planificado.
          </p>
          <p>
            Si tiene alguna pregunta sobre nuestro uso de cookies, envíenos un
            mensaje a través de nuestro formulario de contactos.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CookiesYPrivacidad;
